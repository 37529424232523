@import 'styles/layers.scss';

@layer uikit {
  .control {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .popover {
    border: none;
    border-radius: 4px;
    background: var(--white);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    max-width: inherit !important;
    width: auto !important;
    margin-left: 8px;
    z-index: 1100;
    position: fixed;
    font-size: 12px;
    --bs-popover-arrow-width: 8px;
    --bs-popover-arrow-height: 6px;
  }
  
  .dark {
    background-color: var(--black18);
    &.popover {
      --bs-popover-bg: var(--black18);
    }
    color: var(--white);
  }
  
  .light {
    background-color: var(--white);
    &.popover {
      --bs-popover-bg: var(--white);
    }
    color: var(--black);
  }
  
  .inner {
    cursor: help;
    fill: #CFD1D5;
  }
  
  .icon {
    display: flex;
    align-items: center;
  }
  
  .noMargin {
    margin: 0;
  }
  
  .body {
    border-radius: 6px;
    padding: 9px 10px;
  }
}
